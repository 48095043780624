<template>
  <div class="div_title">
    <context :context=cate />
    <span>
      <InputText v-model="noticeKeyword" v-on:keyup.enter="click('SELECT_NOTICE')" placeholder="검색어" size="small" class="input-keyword"/>  
      <Button label="조회" v-on:click="click('SELECT_NOTICE')" style="" raised size="small" ></Button>
    </span>
  </div>
  <div class="card" >
    <div id="app-notice" v-if="noticeList.length === 0" class="no-result"> 
      조회 내역이 없습니다.
    </div>
    <div id="app-notice" v-else >
      <Accordion value="0">
        <AccordionPanel :value="index+1" v-for="(notice, index) in noticeList" :key="notice.SEQ" >
          <AccordionHeader :class="index%2===1 ? 'datatable-background' : ''"> 
            {{ notice.TITLE }}
          </AccordionHeader>
          <AccordionContent>
            <noticec @noticeClick="click" :content=notice.CONTENT type="notice" class="content" :key="noticecKey"/>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </div>
    <div v-if="noticeList.length !== 0" >
      <Divider />
      <div class="div_center">
        <label @click="paging('prev')" class="link" >
          이전
        </label>
        <span style="margin:20px">{{ page }}</span>
        <label @click="paging('next')" class="link">
          다음
        </label>
      </div>
    </div>
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>
<script>

// import { useMeta } from 'vue-meta'
import { ref, inject } from 'vue'
import context from '@/components/common/Context.vue'
import noticec from '@/components/content/notice_content.vue'
import ssr from './components/common/ssr'

export default {
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 공지사항'
    const keywords = '공지사항,공지,노티스,notice,geek9,직구,필독,정책,매뉴얼,규정,해외직구,규칙,내규'
    const description = '개인해외직구, 기업물류업무에 대한 GEEK9의 정책/내규/규정입니다.'
    const geek9Url = ssr.GEEK9_URL + 'notice'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const expandedRows = ref({})
    const cate = ref([
      { label: '게시판', route:'https://contents.geek9.kr' },
      { label: '공지', route: '/notice' }
    ])
    return {
      expandedRows, cate, addJsonLd
    }
  },
  components: {
    context, noticec
  },  
  mounted() {
    this.addJsonLd()
    this.click('SELECT_NOTICE')
    this.api.setPage('notice')
    this.$emit("setMenu", {page:'notice'})
    console.log(this.api.getCookies('page'))
  },
  data() { 
    return { 
      noticecKey:-1,
      noticeKeyword:undefined,
      idx : '-1',
      page:1,
      noticeList: Array(),
    }
  },
  methods: {
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      let keyword = this.noticeKeyword
      await this.api.axiosGql('SELECT_NOTICE', {type:'SELECT_NOTICE', noticeType: 'notice', noticeYn:true, useYn:true, page:this.page, keyword:keyword})
      .then( async (result) => {
        if(result.data.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
        } else this.noticeList = result.data
      })
      .catch( (err) => {
        console.log(err)
      })
    },
    async click(type) {
      if(type === 'SELECT_NOTICE') {
        let keyword = this.noticeKeyword
        this.page = 1
        await this.api.axiosGql(type, {type:type, noticeType: 'notice', noticeYn:true, page:this.page, keyword:keyword, useYn:true})
        .then( async (result) => {
          if(result.data.length === 0) alert('데이터가 없습니다.')
          else this.noticeList = result.data
        })
        .catch( (err) => {
          console.log(err)
        })
      } else if(type === 'INSERT_NOTICE') {
        const insertNotice = this.$refs.insertNotice
        const useYn = insertNotice.$refs.useYn.checked
        const priority = insertNotice.$refs.priority.value
        const title = insertNotice.$refs.title.value
        const content = insertNotice.$refs.content.value
        const noticeType = insertNotice.$refs.type.value
        if(title === '' || content === '' || noticeType ==='') this.$notify('필수 입력값이 누락되었습니다.')
        else {
          await this.api.axiosGql(type, {type:'INSERT_NOTICE', noticeType:noticeType, useYn:Boolean(useYn), priority:Number(priority), title:title, content:content})
          .then( async (result) => {
            if(result === null || result === undefined) this.$notify('다시 시도해주세요.')
            else {
              this.$notify('등록되었습니다.')
              this.click('SELECT_NOTICE')
            }
          }).catch((err) => console.log(err) )
        }
      }
    },
  }
}
</script>
<style scoped>
.datatable-background {
  background-color:#f9f9f9;
}
</style>